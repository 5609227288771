import { useTranslation } from 'next-i18next';
import React from 'react';
import NotFoundPageCard from '../components/not-found-page-card';
import theme from '../styles/theme';

type ErrorContainerProps = {
  title?: string;
  subtTitle?: string;
  text1?: string;
  text2?: string;
  text3?: string;
  email?: string;
};

const ErrorContainer: React.FC<ErrorContainerProps> = ({
  title,
  subtTitle,
  text2,
  text1,
  text3,
  email,
}: ErrorContainerProps) => {
  const { t } = useTranslation('not-found-page');
  return (
    <div className="not-found-container">
      {title && <h1>{title}</h1>}
      {subtTitle && <h2>{subtTitle}</h2>}
      {text1 && <p>{text1 ? text1 : ''}</p>}
      <p>
        {text2 ? text2 : ''}
        <a href={`mailto:${email}`}>{email}</a>
        {text3 ? text3 : ''}
      </p>
      <div className="flex-container">
        <div className="cards-container">
          <NotFoundPageCard
            title={t('linkCards.card1.title')}
            text={t('linkCards.card1.text')}
            imgSrc="/static/images/not-found-img-1.png"
            imgAlt="not-found-img-1"
            url={'/'}
            blank
            buttonText={t('linkCards.card1.button')}
          ></NotFoundPageCard>
          <NotFoundPageCard
            title={t('linkCards.card2.title')}
            text={t('linkCards.card2.text')}
            imgSrc="/static/images/not-found-img-2.png"
            imgAlt="not-found-img-2"
            url={'https://www.punamusta.com/'}
            buttonText={t('linkCards.card2.button')}
          ></NotFoundPageCard>
          <NotFoundPageCard
            title={t('linkCards.card3.title')}
            text={t('linkCards.card3.text')}
            imgSrc="/static/images/not-found-img-3.png"
            imgAlt="not-found-img-3"
            url={'https://www.punamusta.com/referenssit/'}
            buttonText={t('linkCards.card3.button')}
          ></NotFoundPageCard>
        </div>
      </div>

      <style jsx>{`
        .not-found-container {
          display: flex;
          width: 100%;
          flex-direction: column;
          align-items: center;
        }
        h1 {
          font-size: 2.8125rem;
          margin: 0;
          margin-top: 40px;
          font-weight: 900;
          font-family: 'Inter', sans-serif;
        }
        h2 {
          font-size: 1.5rem;
          margin: 0;
          text-align: center;

          font-family: 'Inter', sans-serif;
        }
        p {
          font-size: 0.875rem;
          margin: 0;
          margin-bottom: 15px;
          text-align: center;
          font-family: 'Inter', sans-serif;
        }
        .flex-container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 25px;
        }
        .cards-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          flex-shrink: 1;
          justify-content: center;
        }
        @media only screen and (min-width: ${theme.breakpoints.values.sm}px) {
          .not-found-container {
            align-items: center;
          }
          h1 {
            font-size: 12.5rem;
            margin-top: 0;
          }
          h2 {
            font-size: 3.125rem;
            text-align: center;
          }
          p {
            font-size: 1.25rem;
            text-align: center;
            margin-bottom: 40px;
          }
        }
        @media only screen and (max-width: 1615px) {
          .cards-container {
            max-width: 986px;
            justify-content: start;
          }
        }
        @media only screen and (max-width: 1122px) {
          .cards-container {
            justify-content: center;
          }
        }
      `}</style>
    </div>
  );
};

export default ErrorContainer;
