import { NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'react-i18next';
import Layout from '../components/layout';
import ErrorContainer from '../containers/error-container';

const NotFound: NextPage = () => {
  const { t } = useTranslation('not-found-page');
  return (
    <Layout backgroundImage={false} title="pageNotFound">
      <ErrorContainer
        title="404"
        subtTitle={t('title')}
        text1={t('text1')}
        text2={t('text2')}
        text3={t('text3')}
        email={'info@punamusta.com'}
      ></ErrorContainer>
    </Layout>
  );
};

export const getStaticProps = async ({ req, res, locale, ...rest }: any) => {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'not-found-page'])),
    },
  };
};

export default NotFound;
