import theme from '../styles/theme';
import DropletLink from './droplet-link';

type NotFoundPageCardProps = {
  imgSrc: string;
  title: string;
  text: string;
  imgAlt: string;
  buttonText: string;
  url: string;
  blank?: boolean;
};

const NotFoundPageCard: React.FC<NotFoundPageCardProps> = ({
  imgSrc,
  text,
  title,
  imgAlt,
  buttonText,
  url,
  blank = false,
}: NotFoundPageCardProps) => {
  return (
    <div className="card-container">
      <img src={imgSrc} alt={imgAlt}></img>
      <div className="text-container">
        <div>
          <h3>{title}</h3>
          <p>{text}</p>
        </div>
        <div>
          <DropletLink
            blank={blank}
            text={buttonText}
            url={url}
            bgColor="#E20815"
            color="#FFFFFF"
          ></DropletLink>
        </div>
      </div>
      <style jsx>{`
        .card-container {
          display: flex;
          flex-direction: row;
          flex-basis: 453px;
          margin: 0;
          margin-bottom: 40px;
        }
        img {
          margin-right: 20px;
          width: 130px;
          height: 130px;
        }
        .text-container {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        h3 {
          margin: 0;

          margin-bottom: 5px;
          font-size: 0.8125rem;
          font-weight: 900;
        }
        p {
          margin: 0;
          margin-bottom: 20px;
          font-size: 0.625rem;
        }
        @media only screen and (min-width: ${theme.breakpoints.values.sm}px) {
          .card-container {
            margin: 20px;
          }
          img {
            width: 200px;
            height: 200px;
          }
          h3 {
            font-size: 1.125rem;
            margin-top: 5px;
            margin-bottom: 10px;
          }
          p {
            font-size: 0.75rem;
          }
        }
      `}</style>
    </div>
  );
};

export default NotFoundPageCard;
