import { makeStyles } from '@material-ui/core';
import Link from 'next/link';
import React from 'react';

const useStyles = makeStyles(() => ({
  button: {
    background: (props: StyleProps) => props.bgColor,
    color: (props: StyleProps) => props.color,
    textTransform: 'capitalize',
    borderRadius: '0px 15px 15px 15px',
    padding: 5,
    paddingLeft: 25,
    paddingRight: 25,
    '&:hover': {
      backgroundColor: (props: StyleProps) => props.bgColor + 95,
    },
  },
}));

type DropletLinkProps = {
  text: string;
  bgColor: string;
  color: string;
  url: string;
  blank?: boolean;
};

type StyleProps = {
  bgColor: string;
  color: string;
};

const DropletLink: React.FC<DropletLinkProps> = ({
  text,
  bgColor,
  color,
  url,
  blank = false,
}: DropletLinkProps) => {
  const classes = useStyles({ bgColor, color });
  return blank ? (
    <Link href={url}>
      <a href={url} className={classes.button}>
        {text}
      </a>
    </Link>
  ) : (
    <a href={url} target={'_blank'} className={classes.button} rel="noreferrer">
      {text}
    </a>
  );
};
export default DropletLink;
